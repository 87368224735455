<template>
    <div class="service-select">
        <label for="service">Select Service:</label>
        <div class="dropdown">
            <div class="selected-service" @click="toggleDropdown">
                <span v-if="selectedService">{{ selectedService.attributes.name }}</span>
                <span v-else>Select a service</span>
            </div>
            <div v-if="isDropdownOpen" class="dropdown-content">
                <input v-model="searchQuery" type="text" placeholder="Search services..." class="search-input" />
                <ul class="dropdown-menu">
                    <li
                        v-for="service in filteredServices"
                        :key="service.attributes.id"
                        @click="selectService(service)"
                    >
                        <span>{{ service.attributes.name }}</span>
                        <span class="service-price">{{ formatPrice(0) }}</span>
                    </li>
                    <li v-if="filteredServices.length === 0" class="no-results">No services found</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Service from '../services/services.service';

    export default {
        props: {
            selectedServiceID: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                services: [],
                selectedService: null,
                isDropdownOpen: false,
                searchQuery: '',
                serviceAPI: new Service(),
            };
        },
        async created() {
            await this.fetchServices();
            this.fetchServicesMinPeriod();
        },
        computed: {
            filteredServices() {
                if (!this.searchQuery) {
                    return this.services;
                }
                return this.services.filter(service =>
                    service.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
                );
            },
        },
        methods: {
            async fetchServices() {
                try {
                    const { data } = await this.serviceAPI.getAll({ disable_pagy: true });
                    this.services = data.data;
                } catch (error) {
                    console.error('Service request error', error);
                }
            },

            fetchServicesMinPeriod() {
                const periods = this.services.map(service => {
                    return service.attributes.min_period_unit;
                });
                const uniquiPeriods = new Set(periods);
                this.$emit('periods', uniquiPeriods);
            },
            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },
            selectService(service) {
                this.selectedService = service;
                this.isDropdownOpen = false;
                this.$emit('serviceSelected', service.attributes.id);
            },
            formatPrice(price) {
                return `$${price.toFixed(2)}`;
            },
        },
    };
</script>

<style scoped lang="scss">
    .service-select {
        margin-bottom: 20px;
        font-family: 'Arial', sans-serif;
    }

    label {
        font-weight: bold;
        margin-bottom: 5px;
        color: #555;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .dropdown {
        position: relative;
    }

    .selected-service {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        cursor: pointer;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:hover {
            border-color: #4caf50;

            .theme-dark & {
                border-color: #ffa500;
            }
        }
    }

    .dropdown-content {
        position: relative;
        width: 100%;
    }

    .search-input {
        width: 100%;
        padding: 10px;
        margin: 5px 0;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-sizing: border-box;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        margin-top: 5px;
        max-height: 200px;
        overflow-y: auto;
        z-index: 1;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
        }
    }

    .dropdown-menu li {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: background-color 0.3s;
        color: #333;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .dropdown-menu li:hover {
        background-color: #f0f0f0;

        .theme-dark & {
            background-color: #222;
        }
    }

    .service-price {
        color: #555;
        font-weight: bold;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .no-results {
        padding: 10px;
        text-align: center;
        color: #999;

        .theme-dark & {
            color: #ccc;
        }
    }
</style>
