<template>
    <div class="software-engineering-form">
        <h2>Software Engineering Service Request Form</h2>
        <form @submit.prevent="submitForm">
            <!-- Service Type Selection -->
            <label for="serviceType">Type of Service:</label>
            <select v-model="formData.serviceType" required>
                <option value="webDevelopment">Web Development</option>
                <option value="mobileDevelopment">Mobile Development</option>
                <option value="dataScience">Data Science</option>
                <option value="cloudSolutions">Cloud Solutions</option>
                <option value="devOps">DevOps</option>
            </select>

            <!-- Project Complexity Level -->
            <label for="complexityLevel">Project Complexity Level:</label>
            <select v-model="formData.complexityLevel" required>
                <option value="basic">Basic</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
                <option value="expert">Expert</option>
            </select>

            <!-- Project Description -->
            <label for="description">Project Requirements:</label>
            <textarea
                v-model="formData.description"
                placeholder="Provide detailed project requirements..."
                required
            ></textarea>

            <!-- Budget Range -->
            <label for="budgetRange">Estimated Budget (USD):</label>
            <input v-model="formData.budgetRange" type="number" placeholder="e.g., 5000" required />

            <!-- Contact Information -->
            <h3>Contact Information</h3>
            <label for="contactName">Name:</label>
            <input v-model="formData.contactName" type="text" required />

            <label for="contactEmail">Email:</label>
            <input v-model="formData.contactEmail" type="email" required />

            <label for="contactPhone">Phone Number:</label>
            <input v-model="formData.contactPhone" type="tel" required />

            <!-- Submit Button -->
            <button type="submit">Submit Request</button>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formData: {
                    serviceType: '',
                    complexityLevel: '',
                    description: '',
                    budgetRange: '',
                    contactName: '',
                    contactEmail: '',
                    contactPhone: '',
                },
            };
        },
        methods: {
            submitForm() {
                console.log('Software engineering service request submitted with the following data:');
                console.log(this.formData);
                this.resetForm();
            },
            resetForm() {
                this.formData = {
                    serviceType: '',
                    complexityLevel: '',
                    description: '',
                    budgetRange: '',
                    contactName: '',
                    contactEmail: '',
                    contactPhone: '',
                };
            },
        },
    };
</script>

<style scoped lang="scss">
    .software-engineering-form {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f5f5f5;
        border-radius: 8px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        font-family: Arial, sans-serif;
    }

    h2 {
        text-align: center;
        color: #333;
    }

    h3 {
        margin-top: 20px;
        color: #666;
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: #333;
    }

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='number'],
    select,
    textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
    }

    textarea {
        resize: vertical;
        min-height: 100px;
    }

    button {
        width: 100%;
        padding: 12px;
        background-color: #007bff;
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    button:hover {
        background-color: #0056b3;
    }

    button:active {
        background-color: #003f8a;
    }

    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
    }
</style>
