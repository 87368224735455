<template>
    <div class="infrastructure-maintenance-form">
        <h2>Infrastructure Maintenance Form</h2>
        <form @submit.prevent="handleSubmit">
            <!-- Date Field -->
            <label for="date">Maintenance Date:</label>
            <input id="date" v-model="formData.date" type="date" required />

            <!-- Asset Type Dropdown -->
            <label for="asset-type">Asset Type:</label>
            <select id="asset-type" v-model="formData.assetType" required>
                <option disabled value="">Select an asset type</option>
                <option value="network">Network</option>
                <option value="server">Server</option>
                <option value="workstation">Workstation</option>
                <option value="storage">Storage</option>
                <option value="other">Other</option>
            </select>

            <!-- Maintenance Type Dropdown -->
            <label for="maintenance-type">Maintenance Type:</label>
            <select id="maintenance-type" v-model="formData.maintenanceType" required>
                <option disabled value="">Select maintenance type</option>
                <option value="preventive">Preventive</option>
                <option value="corrective">Corrective</option>
                <option value="predictive">Predictive</option>
                <option value="emergency">Emergency</option>
            </select>

            <!-- Priority Radio Buttons -->
            <label>Priority:</label>
            <div>
                <label>
                    <input v-model="formData.priority" type="radio" value="low" required />
                    Low
                </label>
                <label>
                    <input v-model="formData.priority" type="radio" value="medium" />
                    Medium
                </label>
                <label>
                    <input v-model="formData.priority" type="radio" value="high" />
                    High
                </label>
            </div>

            <!-- Notes Textarea -->
            <label for="notes">Maintenance Notes:</label>
            <textarea id="notes" v-model="formData.notes" placeholder="Add any additional information here"></textarea>

            <!-- Submit Button -->
            <button type="submit">Submit Maintenance Request</button>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formData: {
                    date: '',
                    assetType: '',
                    maintenanceType: '',
                    priority: '',
                    notes: '',
                },
            };
        },
        methods: {
            handleSubmit() {
                console.log('Submitted data:', this.formData);
                // Add your submission logic here
                // e.g., send data to an API or clear the form
                this.resetForm();
            },
            resetForm() {
                this.formData = {
                    date: '',
                    assetType: '',
                    maintenanceType: '',
                    priority: '',
                    notes: '',
                };
            },
        },
    };
</script>

<style scoped lang="scss">
    .infrastructure-maintenance-form {
        max-width: 500px;
        margin: auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    label {
        display: block;
        margin-top: 10px;
        font-weight: bold;
    }

    input,
    select,
    textarea,
    button {
        width: 100%;
        margin-top: 5px;
        padding: 8px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button {
        margin-top: 20px;
        background-color: #007bff;
        color: #fff;
        cursor: pointer;
        border: none;
    }

    button:hover {
        background-color: #0056b3;
    }
</style>
