<template>
    <div class="order-form">
        <h2>Order Form</h2>

        <user-select @selected-user="handleSelectedUser" />

        <service-select @periods="handleServicesPeriods" @serviceSelected="handleSelectedService" />

        <div class="form-group" :class="{ 'has-error': errors.name }">
            <label for="name">Name:</label>
            <input id="name" v-model="form.name" type="text" />
            <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.revivalDate }">
            <label for="revivalDate">Revival Date:</label>
            <input id="revivalDate" v-model="form.revivalDate" type="date" />
            <span v-if="errors.revivalDate" class="error-message">{{ errors.revivalDate }}</span>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.currency }">
            <label for="currency">Currency:</label>
            <select id="currencySelector" v-model="form.currency">
                <option v-for="currency in currencies" :key="currency" :value="currency">
                    {{ currency }}
                </option>
            </select>
            <span v-if="errors.currency" class="error-message">{{ errors.currency }}</span>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.price }">
            <label for="price">Price:</label>
            <input id="price" v-model.number="form.price" type="number" min="0" />
            <span v-if="errors.price" class="error-message">{{ errors.price }}</span>
        </div>

        <button class="submit-button" @click="submitOrder">Submit Order</button>
    </div>
</template>

<script>
    import UserSelect from './UserSelect.vue';
    import ServiceSelect from './ServiceSelect.vue';
    import Service from '../services/service-orders.service';
    import { mapGetters } from 'vuex/dist/vuex.common.js';

    export default {
        components: {
            UserSelect,
            ServiceSelect,
        },
        data() {
            return {
                periods: [],
                service: new Service(),
                form: {
                    userID: null,
                    serviceID: null,
                    currency: '',
                    name: null,
                    revovalDate: null,
                    price: null,
                },
                errors: {
                    userID: '',
                    serviceID: '',
                    currency: '',
                    name: '',
                    revivalDate: '',
                    price: '',
                },
            };
        },
        computed: {
            ...mapGetters('currency', ['currencies']),
        },
        methods: {
            submitOrder() {
                this.clearErrors();
                if (this.validateForm()) {
                    // this.saveOrderForm();
                    console.log(this.form);
                }
            },
            async saveOrderForm() {
                try {
                    const formData = new FormData();
                    formData.append('client_service[user_id]', this.form.userID);
                    formData.append('client_service[service_id]', this.form.serviceID);
                    formData.append('client_service[start_date]', this.form.startDate);
                    formData.append('client_service[end_date]', this.form.revivalDate);
                    formData.append('client_service[amount_cents]', this.form.price);
                    formData.append('client_service[amount_currency]', this.form.currency);
                    formData.append('client_service[min_period_amount_cents]', this.form.minPeriodPrice);

                    const res = await this.service.save(formData);

                    if (res.status == 201) {
                        this.successMessage();
                        this.$router.push({ path: 'list' });
                    }
                } catch (error) {
                    console.error('Service request error', error);
                }
            },
            validateForm() {
                let isValid = true;
                if (!this.form.userID) {
                    this.errors.userID = 'Please select a user.';
                    isValid = false;
                }
                if (!this.form.serviceID) {
                    this.errors.serviceID = 'Please select a service.';
                    isValid = false;
                }
                if (!this.form.name || this.form.name.length < 3) {
                    this.errors.name = 'Name must be longer than 3 characters.';
                    isValid = false;
                }
                if (!this.form.revivalDate) {
                    this.errors.revivalDate = 'Please select a revival date.';
                    isValid = false;
                }
                if (!this.form.currency) {
                    this.errors.currency = 'Please select a currency.';
                    isValid = false;
                }
                if (this.form.price < 0) {
                    this.errors.price = 'Price cannot be negative.';
                    isValid = false;
                }
                if (this.form.price == 0 || this.form.price == null) {
                    this.errors.price = 'Price cannot be Zero.';
                    isValid = false;
                }
                return isValid;
            },
            clearErrors() {
                this.errors = {
                    userID: '',
                    serviceID: '',
                    startDate: '',
                    revivalDate: '',
                    currency: '',
                    minPeriod: '',
                    quantity: '',
                    minPeriodPrice: '',
                    price: '',
                };
            },
            handleServicesPeriods(periods) {
                this.periods = periods;
            },
            handleSelectedUser(userID) {
                this.form.userID = userID;
            },
            handleSelectedService(serviceID) {
                this.form.serviceID = serviceID;
            },
            successMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .order-form {
        max-width: 450px;
        margin: 50px auto;
        padding: 25px;
        background: linear-gradient(135deg, #f7f9fc, #e2ebf0);
        border-radius: 12px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        font-family: 'Arial', sans-serif;

        .theme-dark & {
            background: #222;
            color: #ffa500;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
        }
    }

    .form-group {
        margin-bottom: 20px;
        position: relative;
    }

    label {
        display: block;
        font-weight: bold;
        color: #555;
        margin-bottom: 8px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    select,
    input[type='number'],
    input[type='text'],
    input[type='date'] {
        width: 100%;
        padding: 12px 16px;
        font-size: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            outline: none;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .error-message {
        color: #e74c3c;
        font-size: 12px;
        margin-top: 5px;

        .theme-dark & {
            color: #ff4c4c;
        }
    }

    .submit-button {
        width: 100%;
        background-image: linear-gradient(to right, #4caf50, #81c784);
        color: white;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-image 0.3s, box-shadow 0.3s;

        &:hover {
            background-image: linear-gradient(to right, #388e3c, #66bb6a);
            box-shadow: 0 8px 15px rgba(0, 128, 0, 0.2);
        }

        .theme-dark & {
            background-image: linear-gradient(to right, #ffa500, #ffb84d);
            color: #222;
        }
    }

    input[type='date'] {
        padding: 10px 14px;
        font-size: 15px;
        color: #333;
        appearance: none;

        .theme-dark & {
            color: #ffa500;
        }
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        background-color: #4caf50;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;

        .theme-dark & {
            background-color: #ffa500;
        }
    }

    .form-group.has-error input,
    .form-group.has-error select {
        border-color: #e74c3c;

        .theme-dark & {
            border-color: #ff4c4c;
        }
    }

    @media (max-width: 500px) {
        .order-form {
            padding: 15px;
        }

        h2 {
            font-size: 1.5em;
        }
    }
</style>
