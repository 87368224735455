<template>
    <div class="hosting-form">
        <h2>Hosting Order Form</h2>
        <form @submit.prevent="submitForm">
            <!-- Hosting Plan Selection -->
            <label for="hostingPlan">Choose Hosting Plan:</label>
            <select v-model="formData.hostingPlan" required>
                <option value="basic">Basic</option>
                <option value="standard">Standard</option>
                <option value="premium">Premium</option>
            </select>

            <!-- Billing Cycle -->
            <label for="billingCycle">Billing Cycle:</label>
            <select v-model="formData.billingCycle" required>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                <option value="two_years">2 Years</option>
            </select>

            <!-- Domain Name Input -->
            <label for="domainName">Domain Name:</label>
            <input v-model="formData.domainName" type="text" placeholder="example.com" required />

            <!-- Additional Features -->
            <label>Additional Features:</label>
            <div class="checkbox-group">
                <input v-model="formData.additionalFeatures" type="checkbox" value="SSL Certificate" />
                <label for="sslCertificate">SSL Certificate</label>

                <input v-model="formData.additionalFeatures" type="checkbox" value="Daily Backup" />
                <label for="dailyBackup">Daily Backup</label>

                <input v-model="formData.additionalFeatures" type="checkbox" value="Site Monitoring" />
                <label for="siteMonitoring">Site Monitoring</label>
            </div>

            <!-- Contact Information -->
            <h3>Contact Information</h3>
            <label for="contactName">Name:</label>
            <input v-model="formData.contactName" type="text" required />

            <label for="contactEmail">Email:</label>
            <input v-model="formData.contactEmail" type="email" required />

            <label for="contactPhone">Phone Number:</label>
            <input v-model="formData.contactPhone" type="tel" required />

            <!-- Submit Button -->
            <button type="submit">Order Hosting</button>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formData: {
                    hostingPlan: '',
                    billingCycle: '',
                    domainName: '',
                    additionalFeatures: [],
                    contactName: '',
                    contactEmail: '',
                    contactPhone: '',
                },
            };
        },
        methods: {
            submitForm() {
                console.log('Form submitted with the following data:');
                console.log(this.formData);
                this.resetForm();
            },
            resetForm() {
                this.formData = {
                    hostingPlan: '',
                    billingCycle: '',
                    domainName: '',
                    additionalFeatures: [],
                    contactName: '',
                    contactEmail: '',
                    contactPhone: '',
                };
            },
        },
    };
</script>

<style scoped lang="scss">
    .hosting-form {
        max-width: 500px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        font-family: Arial, sans-serif;
    }

    h2 {
        text-align: center;
        color: #333;
    }

    h3 {
        margin-top: 20px;
        color: #666;
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: #333;
    }

    input[type='text'],
    input[type='email'],
    input[type='tel'],
    select {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
    }

    select {
        background-color: #fff;
    }

    .checkbox-group {
        display: flex;
        flex-direction: column;
    }

    input[type='checkbox'] {
        margin-right: 10px;
    }

    button {
        width: 100%;
        padding: 12px;
        background-color: #007bff;
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    button:hover {
        background-color: #0056b3;
    }

    button:active {
        background-color: #003f8a;
    }

    input:focus,
    select:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
    }
</style>
