<template>
    <div class="user-select">
        <label for="clientUser">Select Client User:</label>
        <div class="dropdown">
            <div class="selected-user" @click="toggleDropdown">
                <user-avatar v-if="selectedUser" :user="selectedUser" />
                <span v-if="selectedUser">{{ selectedUser.full_name }}</span>
                <span v-else>Select a user</span>
            </div>
            <div v-if="isDropdownOpen" class="dropdown-content">
                <input v-model="searchQuery" type="text" placeholder="Search users..." class="search-input" />
                <ul class="dropdown-menu">
                    <li v-for="user in filteredUsers" :key="user.attributes.id" @click="selectUser(user)">
                        <user-avatar :user="user.attributes" />
                        <span>{{ user.attributes.full_name }}</span>
                    </li>
                    <li v-if="filteredUsers.length === 0" class="no-results">No users found</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Service from '../../Users/services/user.service';
    import UserAvatar from '../../../components/UserAvatar.vue';

    export default {
        components: {
            UserAvatar,
        },
        props: {
            selectedUserID: {
                type: Array,
            },
        },
        data() {
            return {
                users: [],
                selectedUser: null,
                isDropdownOpen: false,
                searchQuery: '',
                service: new Service(),
            };
        },
        async created() {
            await this.fetchUsers();
        },
        computed: {
            filteredUsers() {
                if (!this.searchQuery) {
                    return this.users;
                }
                return this.users.filter(user =>
                    user.attributes.full_name.toLowerCase().includes(this.searchQuery.toLowerCase()),
                );
            },
        },
        methods: {
            async fetchUsers() {
                try {
                    const { data } = await this.service.getAll({ disable_pagy: true });
                    this.users = data.data;
                } catch (error) {
                    console.log('User request error', error);
                }
            },
            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },
            selectUser(user) {
                this.selectedUser = user.attributes;
                this.isDropdownOpen = false;
                this.searchQuery = '';
                this.$emit('selected-user', user.attributes.id);
            },
        },
    };
</script>

<style scoped lang="scss">
    .user-select {
        margin-bottom: 20px;
        font-family: 'Arial', sans-serif;
    }

    label {
        font-weight: bold;
        margin-bottom: 5px;
        color: #555;

        .theme-dark & {
            color: #ffa500;
        }
    }

    span {
        margin-left: 5px;
    }

    .dropdown {
        position: relative;
    }

    .selected-user {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:hover {
            border-color: #4caf50;

            .theme-dark & {
                border-color: #ffa500;
            }
        }
    }

    .dropdown-content {
        position: relative;
        width: 100%;
    }

    .search-input {
        width: 100%;
        padding: 10px;
        margin: 5px 0;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-sizing: border-box;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        margin-top: 5px;
        max-height: 200px;
        overflow-y: auto;
        z-index: 1;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
        }
    }

    .dropdown-menu li {
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: background-color 0.3s, color 0.3s;
        color: #333;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .dropdown-menu li:hover {
        background-color: #f0f0f0;

        .theme-dark & {
            background-color: #222;
        }
    }

    .no-results {
        padding: 10px;
        text-align: center;
        color: #999;

        .theme-dark & {
            color: #ccc;
        }
    }

    .user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }
</style>
